import React from 'react';

export const Categories = (props) => {
  return (
    <div id="categories" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>categories</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.id}-${i}`} className="col-xs-12 col-md-3">
                  <img src={d.img} alt="" className="category-img page-scroll" />{' '}
                  {/* <h3>{d.title}</h3>
                  <p>{d.text}</p> */}
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
